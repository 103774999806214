import React from "react";
import { Link } from "react-router-dom";
// import PropTypes from "prop-types";

const Navbar = props => {
  return (
    <div className="nav-bar">
      <header className="title">
        <Link to="/" style={{ margin: "auto" }}>
          My Tiny News
        </Link>
      </header>
      <Link className="nav-button" to="/about">
        About Us
      </Link>
      <Link className="nav-button" to="/articles">
        Articles
      </Link>
    </div>
  );
};

// Navbar.propTypes = {};

export default Navbar;
