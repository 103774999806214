import React from "react";
import { Link } from "react-router-dom";

const ArticleImage = ({ image, articleUrl }) => {
  return (
    <div className="article-img-container">
      <Link to={articleUrl}>
        <img src={image} alt="img" />
      </Link>
      <div className="layer"></div>
    </div>
  );
};

export default ArticleImage;
