import React from "react";
import { Link } from "react-router-dom";
import ArticleImage from "./ArticleImage";
import articles from "../data/articles.js";
import { Parser } from "html-to-react";

function Landing() {
  const iconStyle = {
    width: "40px",
    height: "40px",
    margin: "5px"
  };
  return (
    <div className="landing-page">
      <div className="left-section">
        <h2 className="section-title">Latest News</h2>
        <br />
        <div className="article-time">{articles["01-001"].time}</div>
        <hr style={{ border: "0.5px solid" }} />
        <ArticleImage
          image={require("../img/articles/01-001.jpg")}
          articleUrl="/article/01-001"
        />
        <b>{articles["01-001"].title}</b>
        <p>
          ` It is the middle of the Queen ! '<br />
          I do n't see anything to run away from . ''
          <br />
          <Link to="/article/01-001">
            <b style={{ fontSize: "14" }}>[ Read More ]</b>
          </Link>
        </p>

        <br />
        <br />
        <div className="article-time">{articles["01-002"].time}</div>
        <hr style={{ border: "0.5px solid" }} />
        <ArticleImage
          image={require("../img/articles/01-002.jpg")}
          articleUrl="/article/01-002"
        />
        <b>{articles["01-002"].title}</b>
        <p>
          Then she hires him and puts him in Johnny 's room .<br />
          ` Now , villain , you are in my power ! '<br />
          <Link to="/article/01-002">
            <b style={{ fontSize: "14" }}>[ Read More ]</b>
          </Link>
        </p>

        <br />
        <br />
      </div>
      <div className="middle-section">
        <div className="article-time">{articles["01-000"].time}</div>
        <hr style={{ border: "0.5px solid" }} />
        <div className="article-img-container">
          <img src={require("../img/articles/01-000.jpg")} alt="img" />
          <div className="layer"></div>
          <h1
            style={{
              position: "absolute",
              left: "0",
              bottom: "0",
              width: "100%",
              color: "black"
            }}
          >
            {articles["01-000"].title}
          </h1>
        </div>
        <p style={{ margin: "5px" }}>
          {Parser().parse(articles["01-000"].text)}
        </p>
      </div>
      <div className="right-section">
        <h2 className="section-title">Little Jounalist</h2>
        <ArticleImage
          image={require("../img/car.jpg")}
          articleUrl="/article/car"
        />
        <p>
          Amokichiat, 3 years old. It had watched over 3 thousands child books.
          <br />
          It use its unique eye to discover the world, and happy to share it
          with everyone!
        </p>
        <h2 className="section-title">Contact Us</h2>
        <a href="/">
          <img
            style={iconStyle}
            src={require("../img/icons/facebook.png")}
            alt="icon"
          />
        </a>
        <a href="/">
          <img
            style={iconStyle}
            src={require("../img/icons/instagram.png")}
            alt="icon"
          />
        </a>
        <a href="/">
          <img
            style={iconStyle}
            src={require("../img/icons/twitter.png")}
            alt="icon"
          />
        </a>
        <a href="https://hsiao-li-chi.com">
          <img
            style={iconStyle}
            src={require("../img/icons/dribbble.png")}
            alt="icon"
          />
        </a>
      </div>
    </div>
  );
}

export default Landing;
