import React from "react";
import articles from "../data/articles.js";
import { Parser } from "html-to-react";
import { useParams } from "react-router-dom";

const ArticlePage = () => {
  let { id } = useParams();
  console.log(articles[id]);
  return (
    <div
      style={{
        margin: "auto",
        width: "60%",
        textAlign: "start"
      }}
    >
      <div className="article-time">{articles[id].time}</div>
      <hr style={{ border: "0.5px solid" }} />
      <img
        src={require("../img/articles/" + articles[id].image)}
        style={{ width: "100%" }}
        alt="img"
      />
      <h1
        className="article-title"
        style={{
          textAlign: "left",
          fontFamily: "Poppins",
          fontWeight: "700"
        }}
      >
        {articles[id].title}
      </h1>
      <p>{Parser().parse(articles[id].text)}</p>
    </div>
  );
};

export default ArticlePage;
