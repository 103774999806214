import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import About from "./components/About";
import ArticlePage from "./components/ArticlePage";
import ListPage from "./components/ListPage";
import Footer from "./components/Footer";
import { Parallax } from "react-parallax";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Parallax
        blur={{ min: 5, max: 15 }}
        bgImage={require("./img/car.jpg")}
        bgImageAlt="the car"
        strength={-20}
      >
        <div>
          <Router>
            <Navbar />
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/about" component={About} />
              <Route exact path="/article/:id" component={ArticlePage} />
              <Route exact path="/articles" component={ListPage} />
              {/* <Route exact path="/signup" component={Signup} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
            </Switch>
          </Router>
          <Footer />
        </div>
      </Parallax>
    </div>
  );
}

export default App;
