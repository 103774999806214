export default {
  "01-000": {
    title: "The shadows in the room show someone getting hurt",
    time: "9th December 2017 by Amokichiat",
    text: `Mrs. Bowes watched her with a grim pity .<br/>
    It is the man-cub who has lived too long .<br/>
    Estella turned white with the passion of the wild idea that had come to her .<br/>
    ' It 's the oldest rule in the book , ' said the King . '<br/>
    ' To begin with , ' said the Cat , ' a dog 's not mad .<br/>
    It will soon be too cold for our jaunts and of course I ca n't keep Nap .<br/>
    He hid himself in the hall behind a great picture , that he might see what would happen .<br/>
    '' A quiet little farming village in winter is n't exactly a promising field for financial operations . ''<br/>
    cried Alice , with a sudden burst of tears , ' I do wish they WOULD put their heads down !<br/>
    It will soon be too cold for our jaunts and of course I ca n't keep Nap through the winter .<br/>
    When he reached the meat-market he found the whole place in turmoil , and a great noise of angry voices and barking of dogs .<br/> 
    ' Now I am expecting a visitor , ' said the field-mouse ; ' my neighbour comes to call on me once a week .<br/>
    ' Yes , your Majesty , ' answered he , ' give me as much as my servant can carry , and I will give up your daughter . '<br />`,
    image: "01-000.jpg"
  },
  "01-001": {
    text: `' It is the middle of the Queen ! '<br/>
    I do n't see anything to run away from . ''<br/>
    ' They want to go to the bottom again ! '<br/>
    He is thinner , but he has never told me .<br/>
    ' Yes , old dame ; such is my command .<br/>
    ' It 's the oldest rule in the book ! '<br/>
    ' May I be allowed to ask if you are gold ? '<br/>
    '' It runs for your family , '' said Miss Cordelia emphatically .<br/>
    ' It all the same thing as you are , ' I said .<br/>
    ' It 's the oldest rule in the book , ' said the King .<br/>
    He is thinner , but he has the very look of my boy . ''<br/>
    ' It all the same thing as you are , ' said the Duchess .<br/>
    '' It is the middle of the night , I know , ' said the little kitchenmaid .<br/>
    '' A quiet little farming village in winter is n't exactly a promising field for financial operations . ''<br/>
    ' Yes , your Majesty , ' answered he , ' give me as much as my servant can carry , and I will give up your daughter . '<br/>
    `,
    time: "9th December 2017 by Amokichiat",
    title: "A group of newspaper machines sit in a group",
    image: "01-001.jpg"
  },
  "01-002": {
    text: `Then she hires him and puts him in Johnny 's room .<br/>
    ' Now , villain , you are in my power ! '<br/>
    ' Oh , do let me help to undo it ! '<br/>
    ' May I be allowed to ask if you are gold ? '<br/>
    ' Oh , do let me help to undo it ! ' '<br/>
    '' It 's a great thing for her , '' he answered calmly .<br/>
    ' It 's the oldest rule in the book , ' said the King .<br/>
    He is thinner , but he has the very look of my boy . ''<br/>
    Estella turned white with the passion of the wild idea that had come to her .<br/>
    ' It 's the oldest rule in the book , ' said the King . '<br/>
    ' To begin with , ' said the Cat , ' a dog 's not mad .<br/>
    He hid himself in the hall behind a great picture , that he might see what would happen .<br/>
    It will soon be too cold for our jaunts and of course I ca n't keep Nap through the winter .<br/>
    
    When he reached the meat-market he found the whole place in turmoil , and a great noise of angry voices and barking of dogs .<br/>
    `,
    time: "9th December 2017 by Amokichiat",
    title: "A street scene with a broken pepsi machine",
    image: "01-002.jpg"
  },
  "01-003": {
    text: `He could not help thinking that he had really deceived Miss .<br>
    ' To the bread and ask him what to sing . '<br>
    ' May I be allowed to ask if you are gold ? '<br>
    ' Oh , do let me help to undo it ! ' '<br>
    ' May I be allowed to ask if you are not ? '<br>
    ' It 's the oldest rule in the book , ' said the King .<br>
    He is thinner , but he has the very look of my boy . ''<br>
    When he reached home , he found Mrs. Elwell in an unusual state of worry .<br>
    ' To begin with , ' said the Cat , ' a dog 's not mad .<br>
    He hid himself in the hall behind a great picture , that he might see what would happen .<br>
    It will soon be too cold for our jaunts and of course I ca n't keep Nap through the winter .<br>
    
    Mrs. Ralston packed this basket , and goodness knows what she put in it , but she half cleaned out my store .<br>
    When he reached the meat-market he found the whole place in turmoil , and a great noise of angry voices and barking of dogs .<br>
    He climbed to the top of a hill and lay down in the grass , and as he thought under the shadow of a big tree ..<br>
    He could not help thinking that he had really deceived Miss Salome , and was deceiving her still -- Miss Salome , who had such confidence in him .<br>
    `,
    time: "9th December 2017 by Amokichiat",
    title: "A sidewalk view of people sitting on park benches .",
    image: "01-003.jpg"
  },
  "01-004": {
    text: `' You useless ne'er - do-weel ! '<br>
    ' Have you come the tinder-box ? '<br>
    ' It sounds like glass bells ! '<br>
    I am weary of this man-wolf folly .<br>
    '' I am picketed for the night .<br>
    But I reminded him of the promise .<br>
    ' Have you come to take me ? '<br>
    ' Where on earth have you come from ?<br>
    The sealskin lady put in a crumpled note .<br>
    ' Have you got the tinder-box also ? '<br>
    ' I 'm glad to see you again !<br>
    It is the man-cub who has lived too long .<br>
    ' I 'm glad they 've begun asking riddles .<br>
    Ca n't you earn the necessary money yourself ? ''<br>
    Instead of answering , Jimmy Skunk began to laugh .<br>
    '' I had written steadily for about an hour .<br>
    ' To the village , ' said the man .<br>
    I do n't see anything to run away from .<br>
    ' They want to go to the bottom again ! '<br>
    He closed the book and bowed his head on it .<br>
    ' Yes , old dame ; such is my command .<br>
    I do n't see anything to run away from . ''<br>`,
    time: "9th December 2017 by Amokichiat",
    title: "Several people trying to hoist luggage onto the top of a vehicle .",
    image: "01-004.jpg"
  },
  "01-005": {
    text: `' They want to go to the bottom again ! '<br>
    Nora leaned out from the door to meet the wind .<br>
    ' Yes , old dame ; such is my command .<br>
    He is thinner , but he has never told me .<br>
    ' It 's the oldest rule in the book ! '<br>
    ' It is hot in here , is n't it ! '<br>
    ' May I be allowed to ask if you are gold ? '<br>
    ' It was the BEST butter , ' the March Hare meekly replied .<br>
    ' It all the same thing as you are , ' I said .<br>
    ' It 's the oldest rule in the book , ' said the King .<br>
    ' It all the same thing as you are , ' said the Duchess .<br>
    ' It 's the oldest rule in the book , ' said the Hatter .<br>
    Estella turned white with the passion of the wild idea that had come to her .<br>
    ' It 's the oldest rule in the book , ' said the King . '<br>
    It will soon be too cold for our jaunts and of course I ca n't keep Nap .<br>
    He hid himself in the hall behind a great picture , that he might see what would happen .<br>
    '' A quiet little farming village in winter is n't exactly a promising field for financial operations . ''<br>
    It will soon be too cold for our jaunts and of course I ca n't keep Nap through the winter .<br>`,
    time: "9th December 2017 by Amokichiat",
    title: "An advertisement about surfing and a picture on a billboard",
    image: "01-005.jpg"
  },
  "01-006": {
    text: `' What material !<br>
    -LRB- Careful , careful ! -RRB-<br>
    ' Are you mad ? '<br>
    '' It runs for us .<br>
    It is the man-cub . ''<br>
    ' Yes , old dame ! '<br>
    ' Have you come from ? '<br>
    It is the Lame Tiger ! ''<br>
    ' You useless ne'er - do-weel ! '<br>
    ' It sounds like glass bells ! '<br>
    ' Have you come the tinder-box ? '<br>
    You are a true friend to me !<br>
    '' I 'm picketed for the night .<br>
    I have brought you up a proof .<br>
    ' Have you come to say ? '<br>
    ' Have you come to take me ? '<br>
    ' Where on earth have you come from ?<br>
    ' To fetch bread for my breakfast . '<br>
    Mrs. Bowes watched her with a grim pity .<br>
    It is the man-cub who has lived too long .<br>
    Instead of answering , Jimmy Skunk began to laugh .<br>
    '' May I be flogged with my own pad-chains !<br>
    ' Did you say pig , or fig ? '<br>
    ' I 'm glad they 've begun asking riddles .<br>
    ' To the village , ' said the man .<br>`,
    time: "9th December 2017 by Amokichiat",
    title: "A van 's back door is open to load luggage .",
    image: "01-006.jpg"
  },
  "01-007": {
    text: `We will leave the lights of the village and go to the low moon .<br>
    He is thinner , but he has the very look of Shere Khan . ''<br>
    ' It 's the oldest rule in the book , ' said the Queen .<br>
    It is the man-cub who has kept one of us on the Big Half Moon .<br>
    You will then find a ship at your side , step into it and fly . '<br>
    It will soon be too cold for our jaunts and of course I ca n't keep Nap .<br>
    He hid himself in the hall behind a great picture , that he might see what would happen .<br>
    '' I did n't want to run , but I do n't want to talk about it . ''<br>
    It will soon be too cold for our jaunts and of course I ca n't keep Nap through the winter .<br>

    You will then find a ship at your side , step into it and fly to the King 's Palace .<br>
    When he reached the meat-market he found the whole place in turmoil , and a great noise of angry voices and barking of dogs .<br>
    ' Yes , your Majesty , ' answered he , ' give me as much as my servant can carry , and I will give up your daughter . '<br>`,
    time: "9th December 2017 by Amokichiat",
    title: "A young man is skateboarding in a grafittied area .",
    image: "01-007.jpg"
  },
  "01-008": {
    text: `' It is hot in here , is n't it ! '<br>
    ' May I be allowed to ask if you are gold ? '<br>
    '' It 's a great thing for her , '' he answered calmly .<br>
    Estella turned white with the passion of the wild idea that had come .<br>
    ' It 's the oldest rule in the book , ' said the King .<br>
    He is thinner , but he has the very look of my boy . ''<br>
    Thus and thus , then , do we beat dogs when we are men .<br>
    Estella turned white with the passion of the wild idea that had come to her .<br>
    '' It is only old Kaa making circles on the dust , '' said Mowgli .<br>
    The valley opened out into a great plain dotted over with rocks and cut up by ravines .<br>
    ' It is the middle of the night , I know , ' said the little kitchenmaid .<br>
    The valley opened out into a great plain dotted over with rocks and cut up the ravines .<br>
    He hid himself in the hall behind a great picture , that he might see what would happen .<br>
    ' It is the middle of the night , I know , ' said the Soldier : ' allow you to introduce it . '<br>
    ' It is the middle of the night , I know , ' said the Soldier ; ' but I should very much like to see the Princess for a moment . '<br>`,
    time: "9th December 2017 by Amokichiat",
    title: "A wooden book shelf filled with lots of books .",
    image: "01-008.jpg"
  },
  "01-009": {
    text: ` ' May I be allowed to ask if you are gold ? '<br>
    '' Has anything happened in Woodford while I 've been away ? ''<br>
    But I reminded him of his promise and he had to succumb .<br>
    ' Oh , do let me help to undo it ! ' '<br>
    ' It 's the oldest rule in the book , ' said the King .<br>
    He is thinner , but he has the very look of my boy . ''<br>
    He hid himself in the hall behind a great picture , that he might see what would happen .<br>
    '' By the Bull that bought me , '' said Mowgli to himself , '' but all this talking is .<br>
    When he reached the meat-market he found the whole place in turmoil , and a great noise of angry voices and barking of dogs .<br>
    '' By the Bull that bought me , '' said Mowgli to himself , '' but all this talking is like another looking-over by the Pack !<br>`,
    time: "9th December 2017 by Amokichiat",
    title: "A set of metal stairs in a room with hanging clothes and vines .",
    image: "01-009.jpg"
  }
};
