import React from "react";
import articles from "../data/articles.js";
import { Link } from "react-router-dom";
const ListPage = () => {
  let result = [];
  for (let [key, value] of Object.entries(articles)) {
    result.push(
      <div className="section-container" key={key}>
        <div className="article-time">{value.time}</div>
        <hr style={{ border: "0.5px solid" }} />
        <div className="section-box">
          <Link
            to={`/article/${key}`}
            style={{
              width: "50%",
              display: "inline-block",
              verticalAlign: "middle",
              paddingRight: "10%"
            }}
          >
            <h1>{value.title}</h1>
          </Link>
          <Link to={`/article/${key}`}>
            <img
              src={require("../img/articles/" + value.image)}
              style={{
                width: "50%",
                display: "inline-block",
                verticalAlign: "middle"
              }}
              alt="img"
            />
          </Link>
          <bar />
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        width: "80%",
        margin: "auto",
        textAlign: "start"
      }}
    >
      {result}
    </div>
  );
};

export default ListPage;
