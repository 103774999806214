import React from "react";

const About = () => {
  return (
    <div
      style={{
        width: "70%",
        color: "white",
        margin: "0 auto",
        height: "100%",
        marginTop: "20px",
        fontFamily: "Roboto Mono",
        backgroundColor: "rgba(0,0,0,0.6)",
        padding: "40px",
        fontWeight: "100"
      }}
    >
      What’s the image of AI in our mind? <br />
      <br />
      Maybe cool, be dangerous, be marvelous, but it can also be friendly, and
      cute. Like Doraemon.
      <br />
      <br />
      Alan Turing said that he design a machine like a child, not an adult. He
      also argued that it might be good if his child computer acted randomly, at
      least some of that time. <br />
      <br />
      Take it easy, and make everything possible. that’s what my project want to
      achieve.
      <br />
      <br />
      This newspaper is written by my little AI journalist. Like most of three
      year old children, it read lot’s of fairytale and children’s books. Such
      like Grimm, one thousand and one night, also fairytale from japan, brazil.
      It will keep on reading lot’s fairytale. It is going to discover the world
      through its tiny digital eyes, and share with people what it saw in the
      newspaper and website.
      <br />
      <br />
      reference:
      <br />
      <br />
      In the machine learning part, I use the "Story teller" by Ryan Kiros
      <br />
      https://github.com/ryankiros/neural-storyteller
      <br />
    </div>
  );
};

export default About;
